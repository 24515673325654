import { createContext, useState, useEffect } from "react";
import { callMsGraph } from "./graph";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "./authConfig";
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';

// Create a context for the table data and related functions
export const TableContext = createContext(null);

/**
 * Context provider component for managing global state and functions.
 * @param {Object} props - The component props.
 * @param {React.ReactNode} props.children - The child components.
 */
export default function Context({ children }) {
    const { instance, accounts } = useMsal();
    const [graphData, setGraphData] = useState(null);
    const [token, setToken] = useState("");
    const [tableData, setTableData] = useState([]);
    const [tokenFetched, setTokenFetched] = useState(false);
    const [search, setSearch] = useState(''); // Add search state
    const [selectedTime, setSelectedTime] = useState({start: new Date(), end: new Date()}); // Add selectedTime state
    const [loading, setLoading] = useState(false); // Add loading state

    // Fetch user details when accounts change
    useEffect(() => {
        fetchUserDetails();
    }, [accounts]);

    // Fetch table data when token is fetched or selected time changes
    useEffect(() => {
        if (tokenFetched) {
            getTableData();
        }
    }, [selectedTime, search]);

    /**
     * Fetches user details using MSAL and Microsoft Graph API.
     */
    const fetchUserDetails = async () => {
        try {
            await instance.acquireTokenSilent({
                ...loginRequest,
                account: accounts[0],
            }).then((response) => {
                setToken(response.accessToken);
                callMsGraph(response.accessToken).then((response) => {
                    setGraphData(response);
                });
            });
        } catch (error) {
            console.log(error);
        }
        return true;
    };

    /**
     * Fetches a JWT token from the backend server.
     */
    const getToken = async () => {
        try {
            await fetch("https://flux-backend.azurewebsites.net/getToken", {
                method: "POST",
                headers: {
                    Authorization: "Bearer " + token,
                },
                body: JSON.stringify({ userEmail: graphData?.mail })
            })
                .then((response) => response.json())
                .then((data) => {
                    localStorage.setItem("jwt_token", data.data);
                    setTokenFetched(true);
                });
        } catch (error) {
            console.error(error);
        }
    };

    /**
     * Handles user logout and clears the cache.
     */
    const handleLogout = async () => {
        try {
            // Ensure that 'instance' is your MSAL.js instance (UserAgentApplication or PublicClientApplication)
            // If using PublicClientApplication, you might not have handleRedirectCallback
            if (instance.handleRedirectCallback) {
              // Wait for any ongoing interaction to complete
              await instance.handleRedirectCallback();
            }
            // Replace 'accounts' with the correct account object
            const accounts = instance.getAllAccounts()[0]; // Retrieve the first account, adjust as needed
            // Perform the logout operation
            await instance.logoutRedirect({
              account: accounts,
              postLogoutRedirectUri: null,
            });
            // Clear the token cache after successful logout
            instance.clearCache();
        } catch (error) {
            console.error("Error during logout:", error);
            // Handle error as needed
        }
    };

    /**
     * Formats a date object to a string in the format YYYY-MM-DD HH:MM:SS (UTC).
     * @param {Date} date - The date object to format.
     * @returns {string} The formatted date string.
     */
    const getCurrentFormattedDateTime = (date) => {
        const padZero = (num) => (num < 10 ? `0${num}` : num);
    
        const year = date.getUTCFullYear();
        const month = padZero(date.getUTCMonth() + 1); // Months are zero-based
        const day = padZero(date.getUTCDate());
        const hours = padZero(date.getUTCHours());
        const minutes = padZero(date.getUTCMinutes());
        const seconds = padZero(date.getUTCSeconds());
    
        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    };

    /**
     * Fetches table data from the backend server based on the selected time and search value.
     */
    const getTableData = async () => {
        setLoading(true); // Set loading to true before fetching data
        try {
            const body = {
                first_param: getCurrentFormattedDateTime(selectedTime.start),
                second_param: getCurrentFormattedDateTime(selectedTime.end)
            };
            
            if (search !== '') {
                body.appname = search;

                const allChangesWithAppnameResponse = await fetch("https://flux-backend.azurewebsites.net/getSearchedChanges", {
                    method: "POST",
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("jwt_token"),
                    },
                    body: JSON.stringify(body)
                });
            
                const allChangesWithAppnameData = await allChangesWithAppnameResponse.json();

                setTableData([]);
                if(!allChangesWithAppnameData.message){
                    setTableData(prevTableData => [...prevTableData, ...allChangesWithAppnameData]);
                }
                else{
                    setTableData([]);
                    toast.error("No data found for the given search query", 
                        {position: "top-center",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light"});
                }
            } else {
                const allChangesResponse = await fetch("https://flux-backend.azurewebsites.net/getAllChanges", {
                    method: "POST",
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("jwt_token"),
                    },
                    body: JSON.stringify(body)
                });

                const allChangesData = await allChangesResponse.json();
                setTableData(allChangesData);
            }
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false); // Set loading to false after fetching data
        }
    };

    return (
        <TableContext.Provider
            value={{
                graphData,
                setGraphData,
                token,
                setToken,
                tokenFetched,
                setTokenFetched,
                getToken,
                getTableData,
                tableData,
                setTableData,
                search, // Provide search state
                setSearch, // Provide setSearch function
                fetchUserDetails,
                getCurrentFormattedDateTime,
                handleLogout,
                selectedTime,
                setSelectedTime,
                loading, // Provide loading state
                setLoading, // Provide setLoading function
            }}>
            {children}
        </TableContext.Provider>
    );
}